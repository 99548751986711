import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component:  () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'home',
        meta: {requiresAuth: true},
        component: () => import('../views/HomeView.vue'),
      },
    ],
  },
  {
    path: '/login',
    component: () => import('../components/Layouts/PublicLayout.vue'),
    meta: { guest: true },
    children: [
      {
        path: '',
        name: 'login',
        meta: { guest: true },
        component: () => import('../views/LoginView.vue'),
      },
    ],
  },
  {
    path: '/accounts',
    component: () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'accounts',
        meta: {requiresAuth: true},
        component: () => import('../views/AccountsView.vue'),
      },
    ],
  },
  {
    path: '/apk',
    component: () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'apk',
        meta: {requiresAuth: true},
        component: () => import('../views/APKView.vue'),
      },
    ],
  },
  {
    path: '/accounts/:id',
    component:  () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'account-details',
        meta: {requiresAuth: true},
        component: () => import('../views/AccountDetails/AccountDetailsView.vue'),
      },
    ],
  },
  {
    path: '/subscriptions',
    component: () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: "subscriptions",
        meta: {requiresAuth: true},
        component: () => import('../views/SubscriptionsView.vue'),
      },
    ],
  },
  {
    path: '/renewals',
    component: () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: "renewals",
        meta: {requiresAuth: true},
        component: () => import('../views/RenewalsView.vue'),
      },
    ],
  },
  {
    path: '/settings',
    component: () => import('../components/Layouts/AppLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        name: 'settings',
        meta: {requiresAuth: true},
        component: () => import('../views/SettingsView.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/settings");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router
