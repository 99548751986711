import axios from 'axios';

const state = {
  user: null,
  token: null
};
const getters = {
  isAuthenticated: state => !!state.user,    
  StateUser: state => state.user,
  StateToken: state => state.token,
};

const actions = {
  async LogIn({commit}, User) {
    let user = {}
    await axios.post('/auth/login', User).then(function(response){
      user = response.data
    })
	await commit('setUser', user)
  },
  async LogOut({commit}){
    let user = null
    commit('LogOut', user)
  }
};
const mutations = {
  setUser(state, user){
      state.user = user.user
      state.token = user.api_token
      Object.assign(axios.defaults.headers, {"api-token" : user.api_token});
  },
  LogOut(state){
      state.user = null
      state.token = null
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
