import Vue from 'vue'

import App from './App.vue'
import VueSession from 'vue-session'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueAxios from 'vue-axios';

import vuetify from './plugins/vuetify'
import router from './router'
import store from './store';
import axios from 'axios';
import moment from 'vue-moment'
import Vuex from 'vuex'
import numeral from 'numeral'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuetify)
Vue.use(VueSession)
Vue.use(VueAxios, axios)
Vue.use(moment)
Vue.use(Vuex)

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})

Vue.filter('formatNumber', function(value) {
  if (value) {
    return numeral(value).format('0,0.00');
  }
})

Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('hh:mm A')
  }
})


axios.defaults.withCredentials = false
axios.defaults.baseURL = 'https://api.keslakay.com/api/v1';
axios.defaults.headers = {
    'Access-Control-Allow-Origin' : '*',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    "Content-Type": "application/json", 
    'solgc-client': "solgc", 
  };
Object.assign(axios.defaults.headers, {"api-token" : store.getters.StateToken})
Vue.prototype.$axios = axios;


axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
  
        originalRequest._retry = true;
        store.dispatch('LogOut')
        return router.push('/login')
    }
  }
})


Vue.config.productionTip = false



new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
