<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script type="text/javascript">

  export default {
    
};
</script>
<style type="text/css">
@media screen and (max-width: 900px){
   .v-data-table-header{
        display:none!important;
    }
    .v-slide-group__prev{
        display:none!important;
    }
}
</style>